<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_649_6384)">
            <path
                d="M17.5737 15.4834L13.4354 11.2881C14.1386 10.1533 14.5466 8.81213 14.5466 7.37278C14.5466 3.30067 11.2901 0 7.27317 0C3.25623 0 0 3.30067 0 7.37278C0 11.445 3.25608 14.7454 7.27317 14.7454C8.81839 14.7454 10.2497 14.2557 11.4274 13.4236L15.5165 17.569C15.8006 17.8567 16.1731 18 16.5451 18C16.9176 18 17.2896 17.8567 17.5742 17.569C18.142 16.9928 18.142 16.0594 17.5737 15.4834ZM7.27317 12.3567C4.55808 12.3567 2.35686 10.1255 2.35686 7.37309C2.35686 4.62066 4.55808 2.38929 7.27317 2.38929C9.98841 2.38929 12.1895 4.62066 12.1895 7.37309C12.1895 10.1255 9.98841 12.3567 7.27317 12.3567Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_649_6384">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
